import { gql } from 'apollo-boost'
import graphql from 'services'
import { UNEXPECTED_ERROR_CODE } from 'helpers/EventCodes'
import Exception from 'helpers/exception'
import getFormattedQuery from 'helpers/getFormattedQuery'
import { FeedlotMonitoring } from 'store/feedlot/types'
import { FeedlotMonitoringQueryParams } from './types'

export const feedlotMonitoringQuery = async (
  token: string,
  params: FeedlotMonitoringQueryParams
): Promise<FeedlotMonitoring[]> => {
  if (!params?.fields) {
    params.fields = {
      feedlotId: true,
      feedlotDescription: true,
      lotsToRun: true,
      pens: {
        id: true,
        code: true,
        lots: {
          id: true,
          description: true,
          processed: true,
          animalsCount: true,
          animalsDelayedWeighingCount: true,
          animalsNotProcessedCount: true,
          registerStatus: true
        }
      }
    }
  }

  const QUERY = gql`
      query QUERY {
        feedlotMonitoring ${getFormattedQuery(params?.fields)}
      }
      `
  try {
    const response = await graphql.query(QUERY, {}, token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(
        statusCode || UNEXPECTED_ERROR_CODE,
        'services/feedlotMonitoring/feedlotMonitoringQuery',
        errors
      )
    }
    return data.feedlotMonitoring
  } catch (error) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(UNEXPECTED_ERROR_CODE, 'services/feedlotMonitoring/feedlotMonitoringQuery', error)
  }
}

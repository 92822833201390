import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Types
import { ApplicationState } from 'store/types'
import { LotMonitoring, PenMonitoring } from 'store/feedlot/types'

// Components
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import Loading from 'components/widgets/Loading'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

// Methods
import { getFeedlotMonitoring, setSelectedLot } from 'store/feedlot/actions'

// Resources
import './styles.scss'
import Button from 'components/widgets/Button'
import AnimalMonitoringModal from 'components/modals/AnimalMonitoringModal'

const Row = (props: { row: any }) => {
  const { row } = props
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const dispatch = useDispatch()

  return (
    <>
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell scope='row'>{row.feedlotDescription}</TableCell>
          <TableCell align='right'>{row.lotsToRun}</TableCell>
          <TableCell align='right'>{row.lotsToValidate}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h6' gutterBottom component='div'>
                  {t('lot.lots')}
                </Typography>
                <Table size='small' aria-label='purchases'>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('pen.pen')}</TableCell>
                      <TableCell>{t('lot.lot')}</TableCell>
                      <TableCell>{t('default.processed')}</TableCell>
                      <TableCell>{t('default.validated')}</TableCell>
                      <TableCell align='right'>{t('field.totalAnimals')}</TableCell>
                      <TableCell align='right'>{t('field.totalOfDelayedWeighingAnimals')}</TableCell>
                      <TableCell align='right'>{t('field.totalOfAnimalsNotProcessed')}</TableCell>
                      <TableCell align='right'>{t('animal.animal_plural')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.pens?.map((pen: PenMonitoring) =>
                      pen?.lots?.map((lot: LotMonitoring) => (
                        <TableRow key={lot.id}>
                          <TableCell scope='row'>{pen.code}</TableCell>
                          <TableCell scope='row'>{lot.description}</TableCell>
                          <TableCell scope='row'>
                            <Grid className={`field ${lot.processed ? 'active' : 'inactive'}`}>
                              {lot.processed ? t('default.yes') : t('default.no')}
                            </Grid>
                          </TableCell>
                          <TableCell scope='row'>
                            <Grid className={`field ${lot.registerStatus ? 'active' : 'inactive'}`}>
                              {lot.registerStatus ? t('default.yes') : t('default.no')}
                            </Grid>
                          </TableCell>
                          <TableCell align='right'>{lot.animalsCount}</TableCell>
                          <TableCell align='right'>{lot.animalsDelayedWeighingCount}</TableCell>
                          <TableCell align='right'>{lot.animalsNotProcessedCount}</TableCell>
                          <Button
                            label={'+'}
                            onClick={() => {
                              dispatch(setSelectedLot(lot.id)), setModalOpen(true)
                            }}
                          />
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
      <AnimalMonitoringModal isOpen={isModalOpen} handleClose={() => setModalOpen(false)} />
    </>
  )
}

const MonitoringScreen = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { feedlotMonitoringLoading, feedlotsMonitoring } = useSelector((state: ApplicationState) => state.feedlot)

  useEffect(() => {
    if (!feedlotsMonitoring.length) dispatch(getFeedlotMonitoring({}))
  }, [feedlotsMonitoring, dispatch])

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t('feedlot.feedlot')}</TableCell>
            <TableCell align='right'>{t('lot.lots_to_run')}</TableCell>
            <TableCell align='right'>{t('lot.lots_to_validate')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!feedlotMonitoringLoading ? (
            feedlotsMonitoring?.map(feedlot => <Row key={feedlot?.feedlotDescription} row={feedlot} />)
          ) : (
            <Loading />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MonitoringScreen
